import React, { Component } from 'react';
export default class portfolio extends Component {
  render() {
    return (
 
      <section id="portfolio">
      <div className="row">
        <div className="twelve columns collapsed">
          <h1>Coming soon.</h1>
          </div>
       </div>
    </section>
    );
  }
}